import { ref, Ref } from '@vue/composition-api'
import repositories from '@/repositories'
import { parentProfile } from './profile'
import { useQuestionnaire } from './questionnaire'

export const useSymptoms = () => {
  const complaints: Ref<any[]> = ref([])
  const loadingSymptoms = ref(false)
  const submittingSymptoms = ref(false)

  const { getCompletedSections } = useQuestionnaire()

  const getSymptoms = async () => {
    loadingSymptoms.value = true
    try {
      const complaintOptions = (await repositories.complaints
        .getComplaints()).data
      const maxIdx = Math.max(...complaints.value
        .map(({ sortby }: any) => sortby))

      complaints.value = [
        ...complaintOptions
          .sort((a: any, b: any) => a.sortby - b.sortby),
        {
          complaintid: 0,
          complaint: 'Other',
          sortby: maxIdx + 1,
          status: 1
        }
      ].map(c => ({
        ...c,
        value: false,
        complaint: c.complaint.replace('\\\'', '\'')
      }))
    } catch (err) { console.log(err) } finally {
      loadingSymptoms.value = false
    }
  }

  const submitSymptoms = async ({
    reason,
    symptoms,
    otherComplaint
  }: any) => {
    submittingSymptoms.value = true
    const {
      patientid,
      lastSymptom: { qPage1Id },
      sleepStatus,
      treatmentsStatus,
      historyStatus
    } = parentProfile.value

    try {
      const info = symptoms.reduce((acc: any, cur: any) => {
        acc[cur.id] = cur.answer
        return acc
      }, {
        patientid,
        status: 1,
        chiefComplaintText: reason,
        complaintid: complaints
          .value.filter((c: any) => c.value)
          .reduce((acc: string, cur: any) => {
            return acc + `${cur.complaintid}|1~`
          }, ''),
        isCompleted: true,
        otherComplaint
      })

      if (qPage1Id) {
        await repositories.symptoms.updateSymptom({ qPage1Id, info })
      } else {
        await repositories.symptoms.createSymptom({ info })
      }
      const havePreMedication = parentProfile.value.premedcheck
      const preMedication = parentProfile.value.premed
      if (sleepStatus >= 1 && treatmentsStatus >= 1 && historyStatus >= 1) {
        parentProfile.value = (await repositories.profile
          .updatePatientQuestionnaire({
            patientid,
            premedcheck: havePreMedication ? 1 : 0,
            premed: havePreMedication ? preMedication : '',

            symptomsStatus: 2,
            sleepStatus: 2,
            treatmentsStatus: 2,
            historyStatus: 2
          })
        ).data
      } else {
        parentProfile.value = (await repositories.profile
          .updatePatientQuestionnaire({
            patientid,
            premedcheck: havePreMedication ? 1 : 0,
            premed: havePreMedication ? preMedication : '',

            symptomsStatus: 1,
            sleepStatus: sleepStatus,
            treatmentsStatus: treatmentsStatus,
            historyStatus: historyStatus
          })
        ).data
      }

      await getCompletedSections()
    } catch (err) {} finally {
      submittingSymptoms.value = false
    }
  }

  return {
    complaints,

    loadingSymptoms,
    getSymptoms,

    submittingSymptoms,
    submitSymptoms
  }
}
