<template>
  <common-form
    #default="{ isValidForm }"
    @submit="submitData"
  >
    <pp-frame>
      <common-loading
        class="d-flex flex-column"
        :loading="loadingCompletedSections || loadingSymptoms"
      >
        <v-alert
          v-if="qSections && qSections.symptoms"
          type="success"
        >
          {{ $t('This page has been completed.') }}<br>
          {{ translate(`
          If you need to make changes to this page,
          please contact {doctor} at {practice}!
          `, { doctor, practice }) }}
        </v-alert>
        <template v-else>
          <v-textarea
            v-model="reason"
            :label="mainReasonLabel"
            outlined
          />
          <h2 class="blue-grey--text">
            {{ $t('Subjective') }}
          </h2>
          <h4 class="blue-grey--text">
            {{ $t('Other Complaints') }}
          </h4>
          <p>{{ $t('Please check any other complaints below.') }}</p>
          <v-row v-if="complaints.length">
            <v-col
              v-for="(complaint, idx) in complaints"
              :key="idx"
              class="py-0 pb-2"
              cols="12"
              md="6"
            >
              <v-checkbox
                v-model="complaints[idx].value"
                :label="complaint.complaint"
                class="ma-0"
                dense
                hide-details
              />
            </v-col>
          </v-row>
          <v-textarea
            v-if="otherOption"
            v-model="other"
            class="mt-4"
            :label="additionalComplaintsLabel"
            outlined
          />

          <h2 class="blue-grey--text mt-6">
            {{ $t('Subjective Signs / Symptoms') }}
          </h2>
          <v-row class="mt-2">
            <v-col
              v-for="(symptom, idx) in symptoms"
              :key="idx"
              class="my-0 mt-2 d-flex align-stretch"
              :order="$vuetify.breakpoint.smAndDown && (idx % 2)
                ? 'last' : 'first'"
              cols="12"
              md="6"
            >
              <pp-select
                v-model="symptom.answer"
                class="flex-grow-1"
                v-bind="symptom"
                :fields="symptom.fields"
                :disabled="(idx === 7 || idx === 9) && !haveBedPartner"
              />
            </v-col>
          </v-row>
        </template>
      </common-loading>

      <template #actions>
        <v-spacer />

        <v-btn
          color="primary"
          type="submit"
          :disabled="!isValidForm"
          :loading="loadingSymptoms ||
            submittingSymptoms ||
            loadingCompletedSections"
        >
          {{ qSections && qSections.symptoms
            ? $t('Next') : $t('Save and Proceed') }}
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
      </template>
    </pp-frame>
  </common-form>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { utils } from 'ui-common'
import { upperFirst } from 'lodash'
import { useQuestionnaire } from '@/use/questionnaire'
import { useSymptoms } from '@/use/symptoms'
import {
  symptoms as symptomsList,
  occasions,
  yesNoSometimes
} from '@/constants/symptoms'
import { useProfile } from '@/use/profile'

export default {
  setup: (_, { root }) => {
    const {
      primaryProfile
    } = useProfile()

    const {
      qSections,
      loadingCompletedSections
    } = useQuestionnaire()

    const {
      complaints,
      loadingSymptoms,
      submittingSymptoms,
      getSymptoms,
      submitSymptoms
    } = useSymptoms()

    const symptoms = ref(symptomsList.map(symptom => ({
      ...symptom,
      answer: 0
    })))

    const reason = ref('')
    const other = ref('')

    const otherOption = computed(() => complaints.value
      ?.find(({ complaintid }) => !complaintid)?.value)

    const haveBedPartner = computed(() =>
      symptoms.value.find(({ id }) => id === 'bed_time_partner').answer)

    const submitData = async () => {
      if (qSections.value.symptoms) {
        utils.navigate('/questionnaire/epworth')
        return
      }

      try {
        await submitSymptoms({
          reason: reason.value,
          symptoms: symptoms.value.map(symptom => {
            switch (symptom.id) {
              case 'told_you_snore':
              case 'bed_time_partner':
              case 'sleep_same_room':
                return {
                  ...symptom,
                  answer: symptom.answer === -1
                    ? 'Don\'t know'
                    : yesNoSometimes[symptom.answer].original
                }
              case 'morning_headaches':
                return {
                  ...symptom,
                  answer: `${symptom.answer}`
                }
              case 'quit_breathing':
                return {
                  ...symptom,
                  answer: occasions[symptom.answer].original
                }
            }

            return symptom
          }),
          complaints: complaints,
          otherComplaint: otherOption.value ? other.value : ''
        })
        utils.navigate('/questionnaire/epworth')
      } catch (err) {}
    }

    onMounted(getSymptoms)

    return {
      qSections,
      loadingCompletedSections,

      loadingSymptoms,
      submittingSymptoms,

      complaints,

      reason,
      other,
      symptoms: computed(() =>
        symptoms.value.map(symptom => {
          symptom.title = root.$i18n.t(symptom.original)
          if (symptom.fields !== undefined) {
            symptom.fields = symptom.fields
              .map(field => {
                field.text = root.$i18n.t(field.original)
                return field
              })
          }
          return symptom
        })
      ),

      otherOption,
      haveBedPartner,

      submitData,

      doctor: computed(() =>
        primaryProfile.value &&
        upperFirst(primaryProfile.value.doctor.name)
      ),
      practice: computed(() =>
        primaryProfile.value &&
        upperFirst(primaryProfile.value.doctor.practice)
      ),
      mainReasonLabel: computed(() =>
        root.$i18n
          .t('What is the main reason you are seeking treatment?')
      ),
      additionalComplaintsLabel: computed(() =>
        root.$i18n.t('Additional Complaints')
      )
    }
  }
}
</script>
