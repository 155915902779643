import constants from './index.json'
const {
  yesNoSometimes,
  frequency,
  occasions
} = constants

const symptoms = [
  {
    original: 'Rate your overall energy level 0 - 10',
    title: 'Rate your overall energy level 0 - 10',
    id: 'energy_level',
    answer: 0,
    min: 0,
    max: 10
  },
  {
    original: 'Have you been told you snore?',
    title: 'Have you been told you snore?',
    id: 'told_you_snore',
    answer: 0,
    fields: yesNoSometimes,
    dontKnow: true
  },
  {
    original: 'Rate the sound of your snoring 0 - 10',
    title: 'Rate the sound of your snoring 0 - 10',
    id: 'snoring_sound',
    answer: 0,
    min: 0,
    max: 10
  },
  {
    original: 'How often do you wake up with morning headaches?',
    title: 'How often do you wake up with morning headaches?',
    id: 'morning_headaches',
    answer: 0,
    fields: frequency
  },
  {
    original: 'On average how many times per night do you wake up?',
    title: 'On average how many times per night do you wake up?',
    id: 'wake_night',
    answer: 0,
    min: 0,
    max: 10
  },
  {
    original: 'Do you have a bed time partner?',
    title: 'Do you have a bed time partner?',
    id: 'bed_time_partner',
    answer: 0,
    fields: yesNoSometimes
  },
  {
    original: 'On average how many hours of sleep do you get per night?',
    title: 'On average how many hours of sleep do you get per night?',
    id: 'hours_sleep',
    answer: 0,
    min: 0,
    max: 15
  },
  {
    original: 'If yes, do they sleep in the same room?',
    title: 'If yes, do they sleep in the same room?',
    id: 'sleep_same_room',
    answer: 0,
    fields: yesNoSometimes
  },
  {
    original: 'Rate your sleep quality 0 - 10',
    title: 'Rate your sleep quality 0 - 10',
    id: 'sleep_qual',
    answer: 0,
    min: 0,
    max: 10
  },
  {
    original: 'How many times per night ' +
      'does your bedtime partner notice you quit breathing?',
    title: 'How many times per night ' +
      'does your bedtime partner notice you quit breathing?',
    id: 'quit_breathing',
    answer: 0,
    fields: occasions
  }
]

export {
  yesNoSometimes,
  frequency,
  occasions,
  symptoms
}
